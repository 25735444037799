import { translate, translateFormatter, type RichTextFormatter } from '@/domains/core/localization/translate';
export const {
  sponsorBrandInfoTitle
} = translate({
  "sponsorBrandInfoTitle": "Informationen"
});
export const sponsorBrandInfoDescription = translateFormatter<{
  brand: string;
}>("Inhalt gesponsert von der Marke {brand}", undefined, undefined, "de");
export const {
  sponsorInfoTitle
} = translate({
  "sponsorInfoTitle": "Informationen zu einem gesponserten Link"
});
export const sponsorInfoModalDescription = translateFormatter("Die Produkte werden als Teil eines kostenpflichtigen optionalen Dienstes beworben, den der H\xE4ndler abonniert hat. <a>Erfahren Sie mehr \xFCber gesponserte Produkte</a>. Sie sehen diese Anzeige basierend auf Ihrer Produktsuche oder auf den auf dieser Seite angezeigten Produkten.", undefined, undefined, "de");
export const sponsorInfoDescription = translateFormatter("Das Produkt wird als Teil eines kostenpflichtigen optionalen Dienstes beworben, den der H\xE4ndler abonniert hat. <a>Erfahren Sie mehr \xFCber gesponserte Produkte</a>. Sie sehen diese Anzeige basierend auf Ihrer Produktsuche oder auf den auf dieser Seite angezeigten Produkten.", undefined, undefined, "de");
export const {
  knowMoreLink
} = translate({
  "knowMoreLink": "https://cdn.manomano.com/legal/consumer-information/de.pdf"
});
export const advertiserName = translateFormatter<{
  name: string;
  strong: RichTextFormatter;
}>("<strong>Name des Werbenden:</strong> {name}", undefined, undefined, "de");
export const advertiserPayerName = translateFormatter<{
  name: string;
  strong: RichTextFormatter;
}>("<strong>Wer zahlt f\xFCr diese Anzeige:</strong> {name}", undefined, undefined, "de");