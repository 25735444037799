import { translate } from '@/domains/core/localization/translate';
export const {
  defaultTopProductsTitle,
  // = mobile
  topProductsB2BTitle,
  topSalesTitle,
  lastSeenTitle,
  similarityTitle,
  favoriteProductsB2BTitle
} = translate({
  "defaultTopProductsTitle": "Top-Verkauf",
  "topProductsB2BTitle": "Top-Profi-Produkte",
  "topSalesTitle": "Aktuelle Top-Verk\xE4ufe",
  "lastSeenTitle": "K\xFCrzlich angesehenen Produkte",
  "similarityTitle": "Das k\xF6nnte Sie auch interessieren",
  "favoriteProductsB2BTitle": "Produktempfehlungen"
});