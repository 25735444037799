import { translate } from '@/domains/core/localization/translate';
export const {
  rootLabel
} = translate({
  "rootLabel": "Forschungsvorschl\xE4ge"
});
export const {
  autocomplete,
  emptySearchB2C,
  emptySearchB2B,
  autocompleteB2B,
  searchHistory,
  deleteHistory,
  searchAI
} = {
  autocomplete: translate({
    "searchBrandTitle": "Top-Marken"
  }),
  emptySearchB2C: translate({
    "searchBrandTitle": "Aktuelle Marken",
    "searchCategoryTitle": "Aktuelle Kategorien",
    "searchQueryTitle": "Beliebte Suchanfragen"
  }),
  emptySearchB2B: translate({
    "searchBrandTitle": "Pro Marken",
    "searchCategoryTitle": "Aktuelle Kategorien",
    "searchQueryTitle": "Suchanfragen unserer Pros"
  }),
  autocompleteB2B: translate({
    "searchProSelection": "Top-Auswahl der Profis"
  }),
  searchHistory: translate({
    "title": "Meine letzten Suchanfragen"
  }),
  deleteHistory: translate({
    "title": "Alles l\xF6schen"
  }),
  searchAI: translate({
    "title": "Exemples de recherches",
    "history": "Recherches r\xE9centes"
  })
};