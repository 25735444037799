import type { ProductCardTranslations } from '@/core/tamagoshi/ProductCard/interfaces';
import { formatCurrency } from '@/domains/core/localization/currency';
import { translate, translateFormatter } from '@/domains/core/localization/translate';
import { CURRENCY } from '@/domains/core/settings/constants';
import { isB2B, isB2C } from '@/domains/core/settings/utils';
import { SponsoredDetailedDescription, type SponsoredData } from '@/productDiscovery/ProductCard/components/SponsoredDetailedDescription';
import { getSponsoredLabelText } from '@/sellerAdsManagement/sponsoredLabelText/sponsoredLabelText';
import { SponsoredDescription } from './components/SponsoredDescription';
export const {
  addToCartLabel,
  addToCartNarrowLabel,
  addToCartSuccess,
  addToCartError,
  mention,
  mentionB2B,
  mentionNoVAT,
  priceVAT,
  priceWithoutVAT,
  inStockLabel,
  topSaleLabel,
  promoTag,
  bestQualityOffer,
  bulkTag,
  couponTag,
  freeDelivery,
  freeDeliveryByMM,
  freeFastDeliveryByMM,
  fastDelivery,
  fastDeliveryByMM,
  headerText,
  seeProduct,
  secondHandLabel,
  blackFridayLabel,
  salesLabel,
  manomanoDaysLabel,
  proDaysLabel,
  recommendedByPros,
  loweredPriceLabel
} = {
  ...translate({
    "mention": "Inkl. MwSt., zzgl. Versand",
    "mentionB2B": "Exkl. Versand",
    "mentionNoVAT": "0% MwST. unter Bedingungen",
    "freeDeliveryLine": "Kostenlose Lieferung",
    "priceVAT": "Inkl. MwSt.",
    "priceWithoutVAT": "Exkl. MwSt.",
    "inStockLabel": "Auf Lager",
    "topSaleLabel": "Top-Verk\xE4ufe",
    "addToCartLabel": "In den Warenkorb",
    "addToCartNarrowLabel": "Hinzuf\xFCgen",
    "addToCartSuccess": "Hinzugef\xFCgt",
    "addToCartError": "Fehler",
    "promoTag": "Promo",
    "bestQualityOffer": "Die ManoMano-Auswahl",
    "bulkTag": "Mengenrabatt",
    "couponTag": "Angebot",
    "freeDelivery": "Kostenlose Lieferung",
    "freeDeliveryByMM": "Lieferung durch ManoMano",
    "freeFastDeliveryByMM": "Lieferung durch ManoMano",
    "fastDelivery": "Lieferung in 1 Tag",
    "fastDeliveryByMM": "Lieferung in 1 Tag bis",
    "headerText": "Wieder kaufen",
    "seeProduct": "Produkt ansehen",
    "secondHandLabel": "Seconde vie",
    "blackFridayLabel": "Black Friday",
    "salesLabel": "Sale",
    "manomanoDaysLabel": "ManoManoDays",
    "proDaysLabel": "ProDays",
    "recommendedByPros": "Die Wahl der Profis",
    "loweredPriceLabel": "Preise"
  })
};
export const proPriceAdvantageTag = translateFormatter<{
  proAdvantagePercentage: number;
}>("Pro Preis -{proAdvantagePercentage}%", undefined, undefined, "de");
export const translations: ProductCardTranslations = {
  addToCart: {
    label: addToCartLabel,
    success: addToCartSuccess,
    error: addToCartError
  },
  informationLine: {
    alternativeModel: ''
  },
  legalMention: {
    mention: isB2B() ? mentionB2B : mention,
    mentionNoVAT
  },
  price: {
    VAT: priceVAT,
    withoutVAT: priceWithoutVAT
  },
  tags: {
    inStock: {
      label: inStockLabel
    },
    sponsored: {
      label: getSponsoredLabelText(),
      description: SponsoredDescription
    },
    topSale: {
      label: topSaleLabel
    },
    promo: {
      label: promoTag
    },
    bestQualityOffer: {
      label: bestQualityOffer
    },
    bulk: {
      label: bulkTag
    },
    coupon: {
      label: couponTag,
      labelShort: ''
    },
    secondHand: {
      label: secondHandLabel
    },
    blackFriday: {
      label: blackFridayLabel
    },
    sales: {
      label: salesLabel
    },
    manomanoDays: {
      label: isB2C() ? manomanoDaysLabel : proDaysLabel
    },
    recommendedByPros: {
      label: recommendedByPros
    },
    loweredPrice: {
      label: loweredPriceLabel
    }
  },
  deliveryLabel: {
    freeDelivery,
    fastDelivery,
    freeDeliveryByMM,
    freeFastDeliveryByMM,
    fastDeliveryByMM
  }
};
export const modelEnrichedTranslate = translateFormatter("{count} Modelle verf\xFCgbar", undefined, undefined, "de");
const cache: {
  [key: string]: string;
} = {};
const formatAndCacheModelTranslation = (modelsCount: number): string => {
  cache[modelsCount] = modelEnrichedTranslate.format({
    count: modelsCount
  }).toString();
  return cache[modelsCount];
};
for (let i = 1; i < 11; i += 1) {
  cache[i] = formatAndCacheModelTranslation(i);
}
export const getProductCardTranslations = (modelsCount: number | undefined, couponAmount?: string | undefined, narrow = false, sponsoredData?: SponsoredData): ProductCardTranslations => {
  translations.informationLine.alternativeModel = modelsCount !== undefined ? cache[modelsCount] ?? formatAndCacheModelTranslation(modelsCount) : '';
  if (couponAmount && translations?.tags.coupon) {
    translations.tags.coupon.label = `${formatCurrency(couponAmount, CURRENCY, {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    })} ${couponTag}`;
    translations.tags.coupon.labelShort = `-${formatCurrency(couponAmount, CURRENCY, {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    })}`;
  }
  translations.addToCart.label = narrow ? addToCartNarrowLabel : addToCartLabel;
  if (sponsoredData && sponsoredData.adPayer) {
    translations.tags.sponsored.description = () => SponsoredDetailedDescription({
      sponsoredData
    });
  }
  return translations;
};