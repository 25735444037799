import { translate, translateFormatter } from '@/core/localization/translate';
export const countdownDaysLeft = translateFormatter<{
  days: number;
}>("Nur noch {days, plural, =1 {# Tag} other {# Tage}}", undefined, undefined, "de");
export const countdownTimeLeft = translateFormatter<{
  hoursFormatted: string;
  minutesFormatted: string;
  secondsFormatted: string;
}>("{hoursFormatted}s {minutesFormatted}m {secondsFormatted}s", undefined, undefined, "de");
export const {
  countdownDays,
  countdownHours,
  countdownMinutes,
  countdownSeconds
} = translate({
  "countdownDays": "TAGE",
  "countdownHours": "STD",
  "countdownMinutes": "MIN",
  "countdownSeconds": "SEK"
});