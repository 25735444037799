import { RichTextFormatter } from '@/core/localization/translate';
import { translate, translateFormatter } from '@/domains/core/localization/translate';
import { footerLegalConsumptionInformationHrefB2B as realFooterLegalConsumptionInformationHrefB2B, footerLegalMentionsHrefB2B as realFooterLegalMentionsHrefB2B, footerLegalProtectionHref as realFooterLegalProtectionHref, footerLegalTermsHrefB2B as realFooterLegalTermsHrefB2B } from './modules/LegalData/translations';
export const footerLegalConsumptionInformationHrefB2B = realFooterLegalConsumptionInformationHrefB2B;
export const footerLegalMentionsHrefB2B = realFooterLegalMentionsHrefB2B;
export const footerLegalProtectionHref = realFooterLegalProtectionHref;
export const footerLegalTermsHrefB2B = realFooterLegalTermsHrefB2B;

// Contact Newsletter Footer/Modal
export const footerContactNewsletterCommon = {
  ...translate({
    "inputLabel": "E-Mail-Adresse"
  }),
  legal: undefined
};
export const footerContactNewsletter = translate({
  "title": "Lust auf (gute) Ratschl\xE4ge und Top-Angebote?",
  "subtitle": "Abonnieren Sie den ManoMano-Newsletter.",
  "subtextLink": "Mehr erfahren"
});
export const footerContactNewsletterIncentiveVariant = translate({
  "title": "Abonnieren Sie unseren Newsletter und erhalten Sie einen 5-Euro-Gutschein*, gute Tipps und Aktionen.",
  "subtitle": "*Der Aktionscode wird nach der Anmeldung per E-Mail versendet und ist ab einem Bestellwert von 150 \u20AC einen Monat ab Erhalt g\xFCltig.",
  "subtextLink": "Mehr erfahren"
});
export const footerContactSubtextIncentiveVariant = translateFormatter<{
  br: RichTextFormatter;
}>("Ihre E-Mail-Adresse wird ausschlie\xDFlich f\xFCr den Versand unserer Newsletter verwendet. <br></br>Die Abmeldung ist \xFCber den im Newsletter integrierten Link m\xF6glich.", undefined, undefined, "de");
export const footerContactSubtext = translateFormatter<{
  br: RichTextFormatter;
}>("Ihre E-Mail-Adresse wird ausschlie\xDFlich f\xFCr den Versand unserer Newsletter verwendet. <br></br>Die Abmeldung ist \xFCber den im Newsletter integrierten Link m\xF6glich.", undefined, undefined, "de");
// Contact Newsletter Success Modal
export const contactNewsletterModalSuccess = translate({
  "title": "Fast geschafft!",
  "subtitle": "Sie sollten eine E-Mail mit einem Best\xE4tigungslink erhalten, um Ihre Anmeldung abzuschlie\xDFen"
});
export const contactNewsletterModalSuccessIncentiveVariant = translate({
  "title": "Wir gratulieren!",
  "subtitle": "Jetzt sind Sie an der Reihe, um die besten Angebote und Sonderaktionen zu erhalten! So k\xF6nnen Sie in Ruhe an Ihrem Geldbeutel basteln"
});
export const {
  availablePlayStore,
  detailsApp,
  detailsProApp,
  downloadAppStore,
  footerAboutSubTitle,
  footerAboutTitle,
  footerAppStoreMamomanoProUrl,
  footerAppStoreMamomanoUrl,
  footerB2cConnectProLink,
  footerBestSellers,
  footerBestSellersHref,
  footerContactCountrySubTitle,
  footerContactCountryTitle,
  footerContactNewsletterInvalidError,
  footerContactNewsletterSubmitText,
  footerContactServiceCallPrice,
  footerContactServiceSubTitle,
  footerContactServiceTitle,
  footerContactSocialNetworkSubTitle,
  footerContactSocialNetworkTitle,
  footerContactSubTitle,
  footerContactTitle,
  footerManoExpress,
  footerManoExpressHref,
  footerManoExpressHrefB2B,
  footerNavigationAbout,
  footerNavigationAboutHref,
  footerNavigationAffiliation,
  footerNavigationAffiliationHref,
  footerNavigationBrands,
  footerNavigationBrandsHref,
  footerNavigationContactUs,
  footerNavigationContactUsHref,
  footerNavigationCouponLegalMention,
  footerNavigationCouponLegalMentionHref,
  footerNavigationHelp,
  footerNavigationHelpHref,
  footerNavigationOrders,
  footerNavigationOrdersHref,
  footerNavigationPressContact,
  footerNavigationPressContactHref,
  footerNavigationRecruitingSellers,
  footerNavigationRecruitingSellersHref,
  footerNavigationRecruitment,
  footerNavigationRecruitmentHref,
  footerPlaymarketMamomanoProUrl,
  footerPlaymarketMamomanoUrl,
  footerProButtonTitle,
  footerQualityPaymentTitle,
  footerReportContentDSALabel,
  footerReportContentDSALink,
  footerSitemapAdvice,
  footerSitemapAdviceHref,
  footerSitemapCurrentOffers,
  footerSitemapCurrentOffersHref,
  footerSitemapProductCategories,
  footerSitemapProductCategoriesHref,
  footerSitemapInspirations,
  footerSitemapInspirationsHref,
  footerSitemapTopProducts,
  footerSitemapTopProductsHref,
  footerToTopButtonAriaLabel,
  footerTrustProSubtitle,
  footerTrustProTitle,
  titleApp,
  titleProApp
} = translate({
  "availablePlayStore": "Jetzt bei Google Play",
  "detailsApp": "Mehr als 4 Millionen Produkte in Ihrer Tasche",
  "detailsProApp": "*Unsere App f\xFCr Handwerker und Gewerbetreibende",
  "downloadAppStore": "Laden im App Store",
  "footerAboutSubTitle": "Hier erfahren Sie alles",
  "footerAboutTitle": "Beratung, Produkte, Preise",
  "footerAppStoreMamomanoUrl": "https://apps.apple.com/de/app/manomano-heimwerken-und-garten/id1503142603",
  "footerAppStoreMamomanoProUrl": "https://apps.apple.com/de/app/manomano-pro/id1498180724",
  "footerB2cConnectProLink": "/anmelden?b2b=true",
  "footerBestSellers": "Top Verk\xE4ufe",
  "footerBestSellersHref": "/bs",
  "footerContactCountrySubTitle": "... und demn\xE4chst vielleicht sogar auf dem Mars",
  "footerContactCountryTitle": "International",
  "footerContactNewsletterInvalidError": "Die angegebene E-Mailadresse ist nicht g\xFCltig.",
  "footerContactNewsletterSubmitText": "Anmelden",
  "footerContactServiceCallPrice": "Anruf zum Ortstarif.",
  "footerContactServiceSubTitle": "ManoMano \u2013 stets zu Diensten!",
  "footerContactServiceTitle": "Wir helfen Ihnen gerne",
  "footerContactSocialNetworkSubTitle": "Die ganze Magie des Internets",
  "footerContactSocialNetworkTitle": "Die ManoMano-Community",
  "footerContactSubTitle": "Wir haben alles. Nur kein Fax.",
  "footerContactTitle": "Informationen & Kontakt",
  "footerNavigationAbout": "Wer sind wir?",
  "footerNavigationAboutHref": "https://about.manomano.com/de",
  "footerNavigationAffiliation": "Affiliate-Programm",
  "footerNavigationAffiliationHref": "https://info.manomano.com/nehmen-sie-an-unserem-programm/",
  "footerNavigationBrands": "Unsere marken",
  "footerNavigationBrandsHref": "/marken",
  "footerNavigationContactUs": "Kontaktieren Sie uns",
  "footerNavigationContactUsHref": "/kontakt",
  "footerNavigationCouponLegalMention": "Allgemeine Bedingungen f\xFCr die Verwendung von Gutscheinen",
  "footerNavigationCouponLegalMentionHref": "/lp/manomano-rabattcodes-sparen-sie-bei-ihrer-bestellung-mit-unseren-exklusiven-angeboten-734",
  "footerNavigationHelp": "Hilfe ben\xF6tigt?",
  "footerNavigationHelpHref": "/hilfe",
  "footerNavigationOrders": "Bestellung verfolgen",
  "footerNavigationOrdersHref": "/meine-bestellungen",
  "footerNavigationPressContact": "Pressekontakt",
  "footerNavigationPressContactHref": "https://about.manomano.com/de/nachrichten/",
  "footerNavigationRecruitingSellers": "H\xE4ndler werden",
  "footerNavigationRecruitingSellersHref": "/lp/auf-manomano-verkaufen-2621",
  "footerNavigationRecruitment": "Bei uns arbeiten",
  "footerNavigationRecruitmentHref": "https://careers.manomano.jobs/en",
  "footerPlaymarketMamomanoProUrl": "https://play.google.com/store/apps/details?id=com.manomano.pro&hl=de",
  "footerPlaymarketMamomanoUrl": "https://play.google.com/store/apps/details?id=com.manomano&hl=de",
  "footerProButtonTitle": "Zum Pro-Bereich",
  "footerQualityPaymentTitle": "Sichere Bezahlung",
  "footerReportContentDSALabel": "Illegale Inhalte melden",
  "footerReportContentDSALink": "https://survey.zohopublic.eu/zs/Y2DHs5?lang=de",
  "footerSitemapAdvice": "Unsere Beratung",
  "footerSitemapAdviceHref": "/unsere-beratung",
  "footerSitemapCurrentOffers": "Unsere aktuellen angebote",
  "footerSitemapInspirations": "Unsere inspirationen",
  "footerSitemapInspirationsHref": "/beratung/unsere-inspirationen-14916",
  "footerSitemapCurrentOffersHref": "/unsere-aktuellen-angebote",
  "footerSitemapProductCategories": "Die beliebtesten Produktkategorien",
  "footerSitemapProductCategoriesHref": "/die-beliebtesten-produktkategorien",
  "footerSitemapTopProducts": "Unsere Rankings",
  "footerSitemapTopProductsHref": "/unsere-rankings",
  "footerManoExpress": "Entdecken ManoExpress",
  "footerManoExpressHref": "/lp/manoexpress-3090",
  "footerManoExpressHrefB2B": "/lp/manoexpress-3091",
  "footerToTopButtonAriaLabel": "Geh wieder hoch",
  "footerTrustProSubtitle": "Ihre Top-Marken zu ausgehandelten Preisen.",
  "footerTrustProTitle": "Sie sind Handwerker oder Gewerbetreibender?",
  "titleApp": "Die ManoMano App",
  "titleProApp": "Entdecken Sie die ManoManoPro App*"
});