import { translate } from '@/domains/core/localization/translate';
export const {
  leonidasMenuExploreCatalogue,
  leonidasMenuLinksTitle,
  leonidasMenuProductsTitle,
  leonidasMenuSeeMoreTitle
} = translate({
  "leonidasMenuExploreCatalogue": "Den Katalog entdecken",
  "leonidasMenuLinksTitle": "Unsere Expertise",
  "leonidasMenuProductsTitle": "Produkte",
  "leonidasMenuSeeMoreTitle": "Mehr sehen"
});