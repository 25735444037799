import { translate } from '@/domains/core/localization/translate';
export const {
  headerSearchB2C,
  headerSearchB2B,
  headerSearchCommon,
  headerSearchAI
} = {
  headerSearchB2C: translate({
    "label": "Auf ManoMano suchen",
    "placeholder": "Suche nach Produkt oder Marke",
    "placeholderMobile": "Auf ManoMano suchen"
  }),
  headerSearchB2B: translate({
    "label": "Suchen Sie nach Produkten und Marken",
    "placeholder": "Suche nach Produkt oder Marke",
    "placeholderMobile": "Suche auf ManoMano"
  }),
  headerSearchCommon: translate({
    "delete": "Sauber",
    "magnifyingGlass": "Suchen"
  }),
  headerSearchAI: translate({
    "placeholder": "Que recherchez-vous ?"
  })
};